import Keycloak from 'keycloak-js';

const keycloakConfig = {
  url: 'https://ask-finance.online/auth',
  realm: 'legal-agent',
  clientId: 'finance-agent'
};

const keycloak = new Keycloak(keycloakConfig);

const initKeycloak = (onAuthenticatedCallback) => {
  keycloak.init({
    onLoad: 'login-required',
    promiseType: 'native',
    checkLoginIframe: false // Disable iframe check for third-party cookies
  }).then((authenticated) => {
    if (authenticated) {
      console.log('Authenticated');
      onAuthenticatedCallback();
    } else {
      console.warn('Not authenticated!');
    }
  }).catch((error) => {
    console.error('Failed to initialize Keycloak', error);
  });
};

const doLogin = keycloak.login;
const doLogout = keycloak.logout;
const getToken = () => keycloak.token;
const isLoggedIn = () => !!keycloak.token;
const updateToken = (successCallback) =>
  keycloak.updateToken(5)
    .then(successCallback)
    .catch(doLogin);
const getUsername = () => keycloak.tokenParsed?.preferred_username;

const KeycloakService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername
};

export default KeycloakService;
