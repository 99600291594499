import React, { useEffect, useState } from 'react';
import KeycloakService from './keycloakService';
import ChatBot from './ChatBot';

const App = () => {
  const [keycloakInitialized, setKeycloakInitialized] = useState(false);

  useEffect(() => {
    KeycloakService.initKeycloak(() => {
      setKeycloakInitialized(true);
    });
  }, []);

  if (!keycloakInitialized) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h1>Welcome to Ask Finance AI</h1>
      <ChatBot token={KeycloakService.getToken()} />
    </div>
  );
};

export default App;

